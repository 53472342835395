import React from "react"
import withLayout from "../../layout"
import SEO from "../../components/SEO"
import {injectIntl} from "react-intl"
import EventDetailTemplate from "../../components/EventDetailTemplate"
import eventsList from "../../utilities/webinars"
import TestimonialImage from "../../images/agency/manuel_fontana.jpg"

const SingleEventPageWrapper = ({intl}) => {
    const {
        name,
        link,
        title,
        date,
        times,
        price,
        image,
        imagesrc,
        id,
        canSubscribe
    } = eventsList.find(event => event.link === "nft-cosa-sono-e-come-verranno-usati-nel-futuro")

    const templateData = {
        canSubscribe,
        name,
        link,
        form: {
            sessionId: "8ab5044d-c8e1-4ce8-91cc-2597c8e76c6c",
            headerAuthorization: "eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJhcGkubGl2ZXN0b3JtLmNvIiwianRpIjoiYmExZjQ5MjktODM0Yy00MDM4LTgzYzYtN2VlM2QxMzA5NTBhIiwiaWF0IjoxNjYyMzczMTYxLCJvcmciOiI2MjM0MTAyNi0wZjdiLTQyNGUtOWIxYy1jNTk0NjQxN2UxYmQifQ.ESasdVUYtzAFKlNQ2zNNulKPCuHwYIlMIYL31LaLZqI"
        },
        title,
        testimonial: {
            image: TestimonialImage,
            name: "Manuel Fontana",
            role: "Full stack developer | Kromin"
        },
        date,
        times,
        location: "events.online",
        price,
        image,
        imagesrc,
        id,
        description: "nft_cosa_sono_e_come_verranno_usati_nel_futuro.description",
        customReservedDescription: {
            title: "nft_cosa_sono_e_come_verranno_usati_nel_futuro.reserved_title",
            subtitle: "nft_cosa_sono_e_come_verranno_usati_nel_futuro.reserved_subtitle",
            paragraph: "nft_cosa_sono_e_come_verranno_usati_nel_futuro.reserved_description"
        },
        list: [
            "nft_cosa_sono_e_come_verranno_usati_nel_futuro.list_1",
            "nft_cosa_sono_e_come_verranno_usati_nel_futuro.list_2",
            "nft_cosa_sono_e_come_verranno_usati_nel_futuro.list_3",
            "nft_cosa_sono_e_come_verranno_usati_nel_futuro.list_4"
        ],
        categories: [
            "events.web_development",
            "events.tech"
        ],
        results: null,
        intl
    }
    const keywords = intl.formatMessage({id:"events.meta_keywords"}).split(",") || []

    return (
        <>
            <SEO
                title={intl.formatMessage({id: "events.meta_title"})}
                description={intl.formatMessage({id: "events.meta_description"})}
                keywords={keywords}
                lang={intl.locale}
            />

            <EventDetailTemplate
                {...templateData}
                intl={intl}
            />
        </>
    )
}

const customProps = {
    localeKey: "events", // same as file name in src/i18n/translations/your-lang/index.js
}

export default withLayout(customProps)(injectIntl(SingleEventPageWrapper))
